import React from 'react';
import { Box, Text } from 'apps/embedded-cbc/components';
import RobotIcon from 'apps/embedded-cbc/components/icons/RobotIcon';
import styled from 'styled-components';

const Custom404 = () => (
  <FullPageContainer>
    <Box sx={{ textAlign: 'center' }}>
      <Box>
        <RobotIcon />
      </Box>
      <Text sx={{ marginTop: '80px' }} variant="h3">
        Oh snap :(
      </Text>
      <Text sx={{ marginTop: '20px' }}>
        The page you’re trying to access might be lost at the moment or doesn’t
        exist. We’re sorry! Try again later.
      </Text>
    </Box>
  </FullPageContainer>
);
const FullPageContainer = styled.div`
  background-color: #f9f9fc;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;

export default Custom404;
